<template>
    <div class="user-edit">
        <div class="main-h1">Личный кабинет</div>
        <div class="card">
            <div class="card-body">
                <table class="table">
                    <tr>
                        <td>БИН/ИИН/ОГРН:</td>
                        <td><input v-model="data.login" readonly disabled></td>
                    </tr>
                    <tr>
                        <td>Город:</td>
                        <td>
                            <input v-model="data.city_title" readonly disabled>
                        </td>
                    </tr>
                    <tr>
                        <td>Роли:</td>
                        <td>
                            <input v-if="data.roles" :value="data.roles.map((item) => item.title).join(', ')" readonly
                                   disabled>
                        </td>
                    </tr>
                    <tr>
                        <td>Организационно правовая форма:</td>
                        <td>
                            <select v-model="data.type" required>
                                <option v-for="type in userOrgTypes" :value="type">{{ type }}</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Имя/наименование:</td>
                        <td><input v-model="data.name"></td>
                    </tr>
                    <tr>
                        <td>Email:</td>
                        <td><input v-model="data.email"></td>
                    </tr>
                    <tr>
                        <td>Номер телефона:</td>
                        <td><input v-model="data.phone"></td>
                    </tr>
                    <tr>
                        <td>Реквизиты:</td>
                        <td><textarea v-model="data.requisite"/></td>
                    </tr>
                    <tr v-if="$store.state.isAdmin() || $store.state.isImplementor() || $store.state.isArbiter()">
                        <td>Уведомления:</td>
                        <td>
                            <div class="notify-list">
                                <label class="notify-label">
                                    <input type="checkbox" :checked="data.notification.includes(1)"
                                           @change="changeNotify(1, $event.target.checked)">
                                    <div class="t">При подаче иска</div>
                                </label>
                                <label class="notify-label">
                                    <input type="checkbox" :checked="data.notification.includes(2)"
                                           @change="changeNotify(2, $event.target.checked)">
                                    <div class="t">При формировании дела</div>
                                </label>
                                <label class="notify-label">
                                    <input type="checkbox" :checked="data.notification.includes(3)"
                                           @change="changeNotify(3, $event.target.checked)">
                                    <div class="t">Загрузка решения</div>
                                </label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Смена пароля (если нужно)</td>
                        <td><input v-model="data.password" type="password"></td>
                    </tr>
                    <tr>
                        <td>Повторите пароль (если нужно)</td>
                        <td><input v-model="data.password2" type="password"></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <button class="btn blue" @click="sendData" :disabled="requestInProgress">Сохранить</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import userOrgTypes from "@/helpers/userOrgTypes";

export default {
    name: "userEdit",
    data() {
        return {
            data: {
                notification: []
            },
            requestInProgress: false,
            userOrgTypes: userOrgTypes,
            cities: [],
        }
    },
    methods: {
        getData() {
            this.$api.get(`profile`).then(res => {
                if (res.data.success) {
                    this.data = res.data.data
                }
            })
        },
        getCities() {
            this.$api.get(`city`).then(res => {
                if (res.data.success) {
                    this.cities = res.data.data
                }
            })
        },
        sendData() {
            this.requestInProgress = true;
            let data = Object.assign({}, this.data);
            this.$api.post(`profile`, data).then(res => {
                this.requestInProgress = false;
                if (res.data.success) {
                    this.data = res.data.data;
                    this.$store.commit('showNotify', {
                        title: 'Сохранено',
                        content: 'Данные успешно сохранены.',
                        type: 'success'
                    })
                } else {
                    this.data = res.data.data;
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'})
                }
            })
        },
        changeNotify(type_id, checked) {
            if (checked && !this.data.notification.includes(type_id)) {
                this.data.notification.push(type_id);
            } else {
                this.data.notification = this.data.notification.filter(item => item !== type_id);
            }
        }
    },
    created() {
        this.getData();
        this.getCities();
    }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.notify-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .notify-label {
        display: flex;
        gap:5px;
        align-items: center;
        .t {

        }
    }
}
</style>